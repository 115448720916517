import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import msaLogo from "../../../images/msa-psg-logo.svg";
import { classNames } from "../../../utils/utils";
import { Link } from "react-router-dom";

export default function SideNavigation(props: {
  pathname: string;
  navItems: any[];
}) {
  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary px-6 pb-4">
        <div className="flex h-16 shrink-0 items-center my-5">
          <img alt="MSA PSG" src={msaLogo} className="w-full" />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {props.navItems.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className={classNames(
                        props.pathname === item.href
                          ? "bg-secondary text-white"
                          : "text-indigo-200 hover:bg-secondary hover:text-white",
                        "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold"
                      )}
                    >
                      <item.icon
                        aria-hidden="true"
                        className={classNames(
                          props.pathname === item.href
                            ? "text-white"
                            : "text-indigo-200 group-hover:text-white",
                          "h-6 w-6 shrink-0"
                        )}
                      />
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className="mt-auto">
              <Link
                to="/settings"
                className={classNames(
                  props.pathname === "/settings"
                    ? "bg-secondary text-white"
                    : "text-indigo-200 hover:bg-secondary hover:text-white",
                  "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold"
                )}
              >
                <Cog6ToothIcon
                  aria-hidden="true"
                  className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                />
                Settings
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
