import { Registrant } from "../../../types/types";

export default function RegistrantCard(props: {
  registrant: Registrant;
  idx: number;
}) {
  return (
    <section aria-labelledby={`applicant-${props.idx + 1}-information-title`}>
      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="registrant-information-title"
            className="text-lg/6 font-medium text-gray-900"
          >
            Registrant {props.idx + 1} Information
            {props.registrant.opjkc3 === "true" ? (
              <span className="ml-3 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Checked-In
              </span>
            ) : (
              <span className=" ml-3 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                Not Checked-In
              </span>
            )}
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Personal details for the registrant.
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Full Name</dt>
              <dd className="mt-1 text-lg text-gray-900">{`${props.registrant.s7ztq3} ${props.registrant.pu0bc3}`}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Gender</dt>
              <dd className="mt-1 text-lg text-gray-900">
                {props.registrant["85tfd3"]}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Age</dt>
              <dd className="mt-1 text-lg text-gray-900">
                {props.registrant["i6b3v3"]}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Email address
              </dt>
              <dd className="mt-1 text-lg text-gray-900">
                <a
                  href={`mailto:${props.registrant.jgfy3}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.registrant.jgfy3}
                </a>
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Registration Type
              </dt>
              <dd className="mt-1 text-lg text-gray-900">
                {props.registrant["5yusq3"]}
              </dd>
            </div>
            <div className="sm:col-span-3">
              <dt className="text-sm font-medium text-gray-500">
                Registrant Participation
              </dt>
              <dd className="mt-1 text-gray-900">
                {props.registrant.bpc1r3 ? (
                  props.registrant.bpc1r3.map((participation: string) => (
                    <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-md font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                      {participation}
                    </span>
                  ))
                ) : (
                  <p className="italic text-lg">None</p>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
}
