import { useEffect, useState } from "react";
import {
  ChartPieIcon,
  HomeIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { fetchFormEntries } from "../../../store/slices/formEntriesSlice";
import { AppDispatch, RootState } from "../../../store";
import LoadingComponent from "../../LoadingComponent";
import { Header } from "./Header";
import SideNavigation from "./SideNavigation";
import MobileMenu from "./MobileMenu";

const navigation = [
  { name: "Dashboard", href: "/", icon: HomeIcon },
  {
    name: "All Registrations",
    href: "/all-registrations",
    icon: TableCellsIcon,
  },
  { name: "Reports", href: "/reports", icon: ChartPieIcon },
];

export default function InternalPage(props: { children: any }) {
  const dispatch = useDispatch<AppDispatch>();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const pathname = window.location.pathname;

  // Select loading, error, and entries data from the Redux store
  const loadingEntries = useSelector(
    (state: RootState) => state.formEntries.loading
  );
  const errorEntries = useSelector(
    (state: RootState) => state.formEntries.error
  );
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Fetch entries only if they haven't been loaded yet
  useEffect(() => {
    if (!entries || entries.length === 0) {
      dispatch(fetchFormEntries());
    }
  }, [dispatch, entries]);

  // Conditional rendering for error state
  if (errorEntries) return <p>Error: {errorEntries}</p>;

  return (
    <div>
      <MobileMenu
        pathname={pathname}
        navItems={navigation}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      <SideNavigation pathname={pathname} navItems={navigation} />

      <div className="lg:pl-72">
        <Header setSidebarOpen={setSidebarOpen} />

        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            {loadingEntries ? <LoadingComponent /> : props.children}
          </div>
        </main>
      </div>
    </div>
  );
}
