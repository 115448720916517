import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export default function SubmissionsCount() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  const totalCount = entries.length;

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500">
        Submissions
      </dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        {totalCount}
      </dd>
    </div>
  );
}
