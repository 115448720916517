import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export default function AgeDistributionTable() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Calculate age distribution by individual age
  const ageDistribution = entries.reduce((acc, entry) => {
    if (entry.meta.Registrants) {
      Object.values(entry.meta.Registrants)
        .filter((registrant) => registrant && registrant["i6b3v3"]) // Assuming i6b3v3 is the age field
        .forEach((registrant) => {
          const age = parseInt(registrant["i6b3v3"], 10);
          if (!isNaN(age)) {
            acc[age] = (acc[age] || 0) + 1;
          }
        });
    }
    return acc;
  }, {} as { [age: number]: number });

  const ageDistributions = Object.entries(ageDistribution).map(
    ([age, count]) => ({
      name: age,
      value: count,
    })
  );

  return (
    <div className="overflow-y-scroll rounded-lg bg-white shadow no-scrollbar">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Age
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Count
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {ageDistributions.map((ageDistribution, idx) => (
            <tr key={idx}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {ageDistribution.name}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {ageDistribution.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
