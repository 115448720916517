import defaultProfilePicture from "../../../images/default-profile-picture.svg";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Bars3Icon, QrCodeIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";
import { getAuth } from "firebase/auth";
import { useIdToken, useSignOut } from "../../../hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  resetState,
  selectEntriesByItemKeyOrEmail,
} from "../../../store/slices/formEntriesSlice";
import { AppDispatch, RootState } from "../../../store";
import { Link, useNavigate } from "react-router-dom";

const userNavigation = [{ name: "Your profile", href: "/settings" }];

export function Header(props: {
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const auth = getAuth();
  const [user, loading, error] = useIdToken(auth);
  const [signOut, loadingSignOut, signOutError] = useSignOut(auth);
  const [searchKey, setSearchKey] = useState("");
  const [debouncedKey, setDebouncedKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false); // Track if input is focused
  const blurTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Reference to store timeout

  // Debounce effect for search
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedKey(searchKey.length >= 3 ? searchKey : "");
    }, 300);
    return () => clearTimeout(handler);
  }, [searchKey]);

  // Show loading spinner only during the debounce delay
  useEffect(() => {
    if (debouncedKey) setIsLoading(true);
    const loadingTimeout = setTimeout(() => setIsLoading(false), 300);
    return () => clearTimeout(loadingTimeout);
  }, [debouncedKey]);

  // Filtered entries based on debounced search key
  const filteredEntries = useSelector((state: RootState) =>
    selectEntriesByItemKeyOrEmail(state, debouncedKey)
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const handleInputFocus = () => {
    // Clear the timeout if the input regains focus
    if (blurTimeoutRef.current) {
      clearTimeout(blurTimeoutRef.current);
      blurTimeoutRef.current = null;
    }
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    // Delay hiding the dropdown to allow link clicks to register
    blurTimeoutRef.current = setTimeout(() => setIsFocused(false), 150);
  };

  const handleSignOut = async (event: any) => {
    const success = await signOut();
    if (success) {
      dispatch(resetState());
      navigate("/sign-in");
    }
  };

  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        onClick={() => props.setSidebarOpen(true)}
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
      </button>

      {/* Separator */}
      <div aria-hidden="true" className="h-6 w-px bg-gray-900/10 lg:hidden" />

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <form action="#" method="GET" className="relative flex flex-1">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <MagnifyingGlassIcon
            aria-hidden="true"
            className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
          />
          <input
            id="search-field"
            name="search"
            type="text"
            value={searchKey}
            autoComplete="off"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            placeholder="Search by confirmation code or email..."
            className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
          />

          {/* Display loading indicator */}
          {isLoading && (
            <div className="absolute p-2 top-full mt-1 w-full border bg-white shadow-md rounded-lg max-h-60 overflow-y-auto z-10 text-gray-500 italic">
              Loading...
            </div>
          )}

          {/* Display dropdown list if there are results and input is focused */}
          {filteredEntries.length > 0 && !isLoading && isFocused && (
            <ul className="absolute py-2 top-full mt-1 w-full border bg-white shadow-md rounded-lg max-h-60 overflow-y-auto z-10">
              {filteredEntries.map((entry) => (
                <li
                  key={entry.id}
                  className="hover:bg-gray-200 cursor-pointer transition-colors"
                >
                  <Link
                    to={`/registrant/${entry.item_key}`}
                    className="block px-2 text-gray-900 py-3"
                  >
                    <span className="font-semibold">{entry.item_key}</span>:{" "}
                    {entry.name}
                    <div className="text-gray-500 text-sm">
                      {entry.meta.Email.join(", ")}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          )}

          {/* Display no results message if there are no matches */}
          {debouncedKey &&
            filteredEntries.length === 0 &&
            !isLoading &&
            isFocused && (
              <div className="absolute p-2 top-full mt-1 w-full border bg-white shadow-md rounded-lg max-h-60 overflow-y-auto z-10 text-gray-700 italic">
                No results found
              </div>
            )}
        </form>
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
            onClick={() => navigate("/scan")}
          >
            <span className="sr-only">View notifications</span>
            <QrCodeIcon aria-hidden="true" className="h-6 w-6" />
          </button>

          {/* Separator */}
          <div
            aria-hidden="true"
            className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
          />

          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <MenuButton className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              <img
                src={user?.photoURL || defaultProfilePicture}
                alt={user?.displayName || ""}
                className="h-8 w-8 rounded-full bg-gray-50 border-2 border-gray-100"
              />
              <span className="hidden lg:flex lg:items-center">
                <span
                  aria-hidden="true"
                  className="ml-4 text-sm/6 font-semibold text-gray-900"
                >
                  {user?.displayName}
                </span>
                <ChevronDownIcon
                  aria-hidden="true"
                  className="ml-2 h-5 w-5 text-gray-400"
                />
              </span>
            </MenuButton>
            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              {userNavigation.map((item) => (
                <MenuItem key={item.name}>
                  <Link
                    to={item.href}
                    className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                </MenuItem>
              ))}
              <MenuItem>
                <Link
                  onClick={handleSignOut}
                  to="#"
                  className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50"
                >
                  Sign Out
                </Link>
              </MenuItem>
            </MenuItems>
          </Menu>
        </div>
      </div>
    </div>
  );
}
