import React, { useState } from "react";
import { updateProfile, updateEmail, User } from "firebase/auth";
import { getAuth } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import ErrorAlert from "../../../components/common/ErrorAlert";
import SuccessAlert from "../../../components/common/SuccessAlert";
import defaultProfilePicture from "../../../images/default-profile-picture.svg";

export default function ProfileSettingsForm() {
  const auth = getAuth();
  const storage = getStorage();
  const user: User | null = auth.currentUser;

  const [displayName, setDisplayName] = useState<string>(
    user?.displayName || ""
  );
  const [email, setEmail] = useState<string>(user?.email || "");
  const [photo, setPhoto] = useState<File | null>(null);
  const [photoURL, setPhotoURL] = useState<string>(
    user?.photoURL || defaultProfilePicture
  );
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files[0]) {
      setPhoto(e.target.files[0]);
      setPhotoURL(URL.createObjectURL(e.target.files[0])); // Show preview of selected photo
    }
  };

  const handleRemovePhoto = async (): Promise<void> => {
    setErrorMessage("");
    setSuccessMessage("");
    if (user && user.photoURL) {
      try {
        const photoRef = ref(storage, `profile-photos/${user.uid}`);
        await deleteObject(photoRef);
        setPhotoURL(defaultProfilePicture);
        setPhoto(null);

        // Update Firebase Authentication profile to remove photoURL
        await updateProfile(user, { photoURL: "" });
        setSuccessMessage("Profile photo removed successfully.");
      } catch (error) {
        console.error("Error deleting profile photo:", error);
        setErrorMessage("Failed to remove profile photo.");
      }
    }
  };

  const handleFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    try {
      let uploadedPhotoURL = photoURL;

      if (photo && user) {
        const photoRef = ref(storage, `profile-photos/${user.uid}`);
        await uploadBytes(photoRef, photo);
        uploadedPhotoURL = await getDownloadURL(photoRef);
      }

      if (user) {
        await updateProfile(user, {
          displayName,
          photoURL:
            uploadedPhotoURL !== defaultProfilePicture
              ? uploadedPhotoURL
              : null,
        });

        if (user.email !== email) {
          await updateEmail(user, email);
        }

        setSuccessMessage("Profile updated successfully.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setErrorMessage("Failed to update profile.");
    }
  };

  return (
    <div className="mt-5 gap-x-0 sm:gap-x-5 gap-y-5 rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      {successMessage && (
        <SuccessAlert
          message={successMessage}
          onClose={() => setSuccessMessage("")}
        />
      )}
      {errorMessage && (
        <ErrorAlert
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}

      <form onSubmit={handleFormSubmit}>
        <div className="space-y-12 sm:space-y-16">
          <div>
            <h2 className="text-base/7 font-semibold text-gray-900">Profile</h2>
            <p className="mt-1 max-w-2xl text-sm/6 text-gray-600">
              This information will be used for internal purposes and only
              within the MSA PSG Registration Team.
            </p>

            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                <label
                  htmlFor="photo"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Photo
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <div className="flex items-center gap-x-3">
                    <img
                      src={photoURL}
                      aria-hidden="true"
                      className="h-12 w-12 rounded-full border-2 border-gray-100"
                      alt="Profile"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="hidden"
                      id="photoUpload"
                    />
                    <label
                      htmlFor="photoUpload"
                      className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer"
                    >
                      Change
                    </label>
                    <button
                      type="button"
                      onClick={handleRemovePhoto}
                      className="rounded-md text-red-500 px-2.5 py-1.5 text-sm font-semibold hover:text-red-800"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="displayName"
                  className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5"
                >
                  Full Name
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary sm:max-w-md">
                    <input
                      id="displayName"
                      name="displayName"
                      type="text"
                      placeholder="First and Last Name"
                      autoComplete="name"
                      className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6"
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="email"
                  className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5"
                >
                  Email
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary sm:max-w-md">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="email@example.com"
                      autoComplete="email"
                      className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            className="inline-flex justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
