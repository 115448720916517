import { FormFieldsType } from "../types/types";

export const formFields: FormFieldsType = {
  "9v3kh3": {
    id: "417",
    field_key: "9v3kh3",
    name: "Registrants",
    description: "",
    type: "divider",
    default_value: "",
    options: "",
    field_order: "2",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  m3mio3: {
    id: "418",
    field_key: "m3mio3",
    name: "Registrant Number",
    description: "",
    type: "hidden",
    default_value: "1",
    options: "",
    field_order: "4",
    required: "0",
    form_id: "14",
    created_at: "2024-10-13 21:56:57",
  },
  s7ztq3: {
    id: "419",
    field_key: "s7ztq3",
    name: "First Name",
    description: "",
    type: "text",
    default_value: "",
    options: "",
    field_order: "6",
    required: "1",
    form_id: "14",
    created_at: "2024-10-13 21:56:57",
  },
  pu0bc3: {
    id: "420",
    field_key: "pu0bc3",
    name: "Last Name",
    description: "",
    type: "text",
    default_value: "",
    options: "",
    field_order: "7",
    required: "1",
    form_id: "14",
    created_at: "2024-10-13 21:56:57",
  },
  "85tfd3": {
    id: "421",
    field_key: "85tfd3",
    name: "Gender",
    description: "",
    type: "select",
    default_value: "",
    options: [
      {
        label: "",
        value: "",
      },
      {
        label: "Male",
        value: "Male",
      },
      {
        label: "Female",
        value: "Female",
      },
    ],
    field_order: "8",
    required: "1",
    form_id: "14",
    created_at: "2024-10-13 21:56:57",
  },
  i6b3v3: {
    id: "422",
    field_key: "i6b3v3",
    name: "Age",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "9",
    required: "1",
    form_id: "14",
    created_at: "2024-10-13 21:56:57",
  },
  jgfy3: {
    id: "424",
    field_key: "jgfy3",
    name: "Email",
    description: "",
    type: "email",
    default_value: "",
    options: "",
    field_order: "11",
    required: "1",
    form_id: "14",
    created_at: "2024-10-13 21:56:57",
  },
  "5yusq3": {
    id: "423",
    field_key: "5yusq3",
    name: "Registration",
    description: "",
    type: "select",
    default_value: "",
    options: {
      "0": {
        label: "",
        value: "",
      },
      "1": {
        label: "Child ages 5-10 ($60)",
        value: "60.00",
      },
      "2": {
        label: "Student ($120)",
        value: "120",
      },
      "3": {
        label: "Adult ($150)",
        value: "150",
      },
      "4": {
        label: "Toddler ages 0-4 (free)",
        value: "0",
      },
    },
    field_order: "12",
    required: "1",
    form_id: "14",
    created_at: "2024-10-13 21:56:57",
  },
  bpc1r3: {
    id: "425",
    field_key: "bpc1r3",
    name: "Member Participation",
    description:
      '<p style="margin:0;font-size:1em;">For Single\'s Halaqa you must fill out this <a href="https://docs.google.com/forms/d/e/1FAIpQLSdSbL1Aw1QffY7C-vg30uoufjAuFmQvJyPh8apW2qU6mcBU5Q/viewform?usp=sharing" target="_blank" style="color:#b82e09;">application form</a>.</p>\r\n<p style="margin:0;font-size:1em;">To register for MATIN Talks please go <a href="https://www.matintalks.com/" target="_blank" style="color:#b82e09;">here</a>.</p>\r\n<p style="margin:0;font-size:0.85em;line-height:1.2em;"><u>Note:</u> Your registration fee is refunded if you are accepted into MATIN Talks.</p>',
    type: "checkbox",
    default_value: "",
    options: {
      "1": {
        label: "Quran Competition Contestant",
        value: "Quran Competition Contestant",
        image: "0",
      },
      "2": {
        label: "Day Care Attendee (Children 4-10)",
        value: "Day Care Attendee (Children 4-10)",
        image: "0",
      },
      "3": {
        label: "Singles Halaqa Attendee",
        value: "Singles Halaqa Attendee",
        image: "0",
      },
    },
    field_order: "13",
    required: "0",
    form_id: "14",
    created_at: "2024-10-13 21:56:57",
  },
  opjkc3: {
    id: "426",
    field_key: "opjkc3",
    name: "Checked In",
    description: "",
    type: "hidden",
    default_value: "false",
    options: "",
    field_order: "15",
    required: "0",
    form_id: "14",
    created_at: "2024-10-13 21:56:57",
  },
  axsgs: {
    id: "460",
    field_key: "axsgs",
    name: "Section Buttons",
    description: "",
    type: "end_divider",
    default_value: "",
    options: "",
    field_order: "16",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 23:32:41",
  },
  "5vpvx3": {
    id: "428",
    field_key: "5vpvx3",
    name: "HTML",
    description:
      '<hr style="border: 1px solid #e8e8e8;" align="center" width="100%" />',
    type: "html",
    default_value: "",
    options: "",
    field_order: "18",
    required: "0",
    form_id: "14",
    created_at: "2024-10-13 21:56:57",
  },
  g8im73: {
    id: "430",
    field_key: "g8im73",
    name: "Phone",
    description: "",
    type: "phone",
    default_value: "",
    options: "",
    field_order: "20",
    required: "1",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  ns7242: {
    id: "429",
    field_key: "ns7242",
    name: "Address",
    description: "",
    type: "address",
    default_value: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
    options: "",
    field_order: "23",
    required: "1",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  "3t8e0": {
    id: "455",
    field_key: "3t8e0",
    name: "Submit",
    description: "",
    type: "submit",
    default_value: "",
    options: "",
    field_order: "24",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  yc9mo: {
    id: "458",
    field_key: "yc9mo",
    name: "Number of Registrants",
    description: "",
    type: "hidden",
    default_value: "",
    options: "",
    field_order: "29",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 23:30:30",
  },
  "6vcz6": {
    id: "461",
    field_key: "6vcz6",
    name: "Subtotal",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "31",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 23:42:44",
  },
  npfp9: {
    id: "457",
    field_key: "npfp9",
    name: "Family Discount",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "33",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 23:28:25",
  },
  wpq5x: {
    id: "463",
    field_key: "wpq5x",
    name: "Family Discount",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "35",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 23:57:37",
  },
  "6ssjl3": {
    id: "433",
    field_key: "6ssjl3",
    name: "Total",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "37",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  o6wv2: {
    id: "434",
    field_key: "o6wv2",
    name: "Next: Additional Information",
    description: "",
    type: "break",
    default_value: "",
    options: "",
    field_order: "39",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  b3bic2: {
    id: "435",
    field_key: "b3bic2",
    name: "Ethnicity",
    description: "",
    type: "select",
    default_value: "",
    options: {
      "1": {
        label: "Afghanistan",
        value: "Afghanistan",
      },
      "2": {
        label: "Aland Islands",
        value: "Aland Islands",
      },
      "3": {
        label: "Albania",
        value: "Albania",
      },
      "4": {
        label: "Algeria",
        value: "Algeria",
      },
      "5": {
        label: "American Samoa",
        value: "American Samoa",
      },
      "6": {
        label: "Andorra",
        value: "Andorra",
      },
      "7": {
        label: "Angola",
        value: "Angola",
      },
      "8": {
        label: "Anguilla",
        value: "Anguilla",
      },
      "9": {
        label: "Antarctica",
        value: "Antarctica",
      },
      "10": {
        label: "Antigua and Barbuda",
        value: "Antigua and Barbuda",
      },
      "11": {
        label: "Argentina",
        value: "Argentina",
      },
      "12": {
        label: "Armenia",
        value: "Armenia",
      },
      "13": {
        label: "Aruba",
        value: "Aruba",
      },
      "14": {
        label: "Australia",
        value: "Australia",
      },
      "15": {
        label: "Austria",
        value: "Austria",
      },
      "16": {
        label: "Azerbaijan",
        value: "Azerbaijan",
      },
      "17": {
        label: "Bahamas",
        value: "Bahamas",
      },
      "18": {
        label: "Bahrain",
        value: "Bahrain",
      },
      "19": {
        label: "Bangladesh",
        value: "Bangladesh",
      },
      "20": {
        label: "Barbados",
        value: "Barbados",
      },
      "21": {
        label: "Belarus",
        value: "Belarus",
      },
      "22": {
        label: "Belgium",
        value: "Belgium",
      },
      "23": {
        label: "Belize",
        value: "Belize",
      },
      "24": {
        label: "Benin",
        value: "Benin",
      },
      "25": {
        label: "Bermuda",
        value: "Bermuda",
      },
      "26": {
        label: "Bhutan",
        value: "Bhutan",
      },
      "27": {
        label: "Bolivia",
        value: "Bolivia",
      },
      "28": {
        label: "Bosnia and Herzegovina",
        value: "Bosnia and Herzegovina",
      },
      "29": {
        label: "Botswana",
        value: "Botswana",
      },
      "30": {
        label: "Bouvet Island",
        value: "Bouvet Island",
      },
      "31": {
        label: "Brazil",
        value: "Brazil",
      },
      "32": {
        label: "Brunei",
        value: "Brunei",
      },
      "33": {
        label: "Bulgaria",
        value: "Bulgaria",
      },
      "34": {
        label: "Burkina Faso",
        value: "Burkina Faso",
      },
      "35": {
        label: "Burundi",
        value: "Burundi",
      },
      "36": {
        label: "Cambodia",
        value: "Cambodia",
      },
      "37": {
        label: "Cameroon",
        value: "Cameroon",
      },
      "38": {
        label: "Canada",
        value: "Canada",
      },
      "39": {
        label: "Cape Verde",
        value: "Cape Verde",
      },
      "40": {
        label: "Cayman Islands",
        value: "Cayman Islands",
      },
      "41": {
        label: "Central African Republic",
        value: "Central African Republic",
      },
      "42": {
        label: "Chad",
        value: "Chad",
      },
      "43": {
        label: "Chile",
        value: "Chile",
      },
      "44": {
        label: "China",
        value: "China",
      },
      "45": {
        label: "Colombia",
        value: "Colombia",
      },
      "46": {
        label: "Comoros",
        value: "Comoros",
      },
      "47": {
        label: "Congo",
        value: "Congo",
      },
      "48": {
        label: "Costa Rica",
        value: "Costa Rica",
      },
      "49": {
        label: "Côte d'Ivoire",
        value: "Côte d'Ivoire",
      },
      "50": {
        label: "Croatia",
        value: "Croatia",
      },
      "51": {
        label: "Cuba",
        value: "Cuba",
      },
      "52": {
        label: "Curacao",
        value: "Curacao",
      },
      "53": {
        label: "Cyprus",
        value: "Cyprus",
      },
      "54": {
        label: "Czech Republic",
        value: "Czech Republic",
      },
      "55": {
        label: "Denmark",
        value: "Denmark",
      },
      "56": {
        label: "Djibouti",
        value: "Djibouti",
      },
      "57": {
        label: "Dominica",
        value: "Dominica",
      },
      "58": {
        label: "Dominican Republic",
        value: "Dominican Republic",
      },
      "59": {
        label: "East Timor",
        value: "East Timor",
      },
      "60": {
        label: "Ecuador",
        value: "Ecuador",
      },
      "61": {
        label: "Egypt",
        value: "Egypt",
      },
      "62": {
        label: "El Salvador",
        value: "El Salvador",
      },
      "63": {
        label: "Equatorial Guinea",
        value: "Equatorial Guinea",
      },
      "64": {
        label: "Eritrea",
        value: "Eritrea",
      },
      "65": {
        label: "Estonia",
        value: "Estonia",
      },
      "66": {
        label: "Ethiopia",
        value: "Ethiopia",
      },
      "67": {
        label: "Fiji",
        value: "Fiji",
      },
      "68": {
        label: "Finland",
        value: "Finland",
      },
      "69": {
        label: "France",
        value: "France",
      },
      "70": {
        label: "French Guiana",
        value: "French Guiana",
      },
      "71": {
        label: "French Polynesia",
        value: "French Polynesia",
      },
      "72": {
        label: "Gabon",
        value: "Gabon",
      },
      "73": {
        label: "Gambia",
        value: "Gambia",
      },
      "74": {
        label: "Georgia",
        value: "Georgia",
      },
      "75": {
        label: "Germany",
        value: "Germany",
      },
      "76": {
        label: "Ghana",
        value: "Ghana",
      },
      "77": {
        label: "Gibraltar",
        value: "Gibraltar",
      },
      "78": {
        label: "Greece",
        value: "Greece",
      },
      "79": {
        label: "Greenland",
        value: "Greenland",
      },
      "80": {
        label: "Grenada",
        value: "Grenada",
      },
      "81": {
        label: "Guadeloupe",
        value: "Guadeloupe",
      },
      "82": {
        label: "Guam",
        value: "Guam",
      },
      "83": {
        label: "Guatemala",
        value: "Guatemala",
      },
      "84": {
        label: "Guernsey",
        value: "Guernsey",
      },
      "85": {
        label: "Guinea",
        value: "Guinea",
      },
      "86": {
        label: "Guinea-Bissau",
        value: "Guinea-Bissau",
      },
      "87": {
        label: "Guyana",
        value: "Guyana",
      },
      "88": {
        label: "Haiti",
        value: "Haiti",
      },
      "89": {
        label: "Honduras",
        value: "Honduras",
      },
      "90": {
        label: "Hong Kong",
        value: "Hong Kong",
      },
      "91": {
        label: "Hungary",
        value: "Hungary",
      },
      "92": {
        label: "Iceland",
        value: "Iceland",
      },
      "93": {
        label: "India",
        value: "India",
      },
      "94": {
        label: "Indonesia",
        value: "Indonesia",
      },
      "95": {
        label: "Iran",
        value: "Iran",
      },
      "96": {
        label: "Iraq",
        value: "Iraq",
      },
      "97": {
        label: "Ireland",
        value: "Ireland",
      },
      "98": {
        label: "Israel",
        value: "Israel",
      },
      "99": {
        label: "Italy",
        value: "Italy",
      },
      "100": {
        label: "Jamaica",
        value: "Jamaica",
      },
      "101": {
        label: "Japan",
        value: "Japan",
      },
      "102": {
        label: "Jersey",
        value: "Jersey",
      },
      "103": {
        label: "Jordan",
        value: "Jordan",
      },
      "104": {
        label: "Kazakhstan",
        value: "Kazakhstan",
      },
      "105": {
        label: "Kenya",
        value: "Kenya",
      },
      "106": {
        label: "Kiribati",
        value: "Kiribati",
      },
      "107": {
        label: "North Korea",
        value: "North Korea",
      },
      "108": {
        label: "South Korea",
        value: "South Korea",
      },
      "109": {
        label: "Kosovo",
        value: "Kosovo",
      },
      "110": {
        label: "Kuwait",
        value: "Kuwait",
      },
      "111": {
        label: "Kyrgyzstan",
        value: "Kyrgyzstan",
      },
      "112": {
        label: "Laos",
        value: "Laos",
      },
      "113": {
        label: "Latvia",
        value: "Latvia",
      },
      "114": {
        label: "Lebanon",
        value: "Lebanon",
      },
      "115": {
        label: "Lesotho",
        value: "Lesotho",
      },
      "116": {
        label: "Liberia",
        value: "Liberia",
      },
      "117": {
        label: "Libya",
        value: "Libya",
      },
      "118": {
        label: "Liechtenstein",
        value: "Liechtenstein",
      },
      "119": {
        label: "Lithuania",
        value: "Lithuania",
      },
      "120": {
        label: "Luxembourg",
        value: "Luxembourg",
      },
      "121": {
        label: "Macao",
        value: "Macao",
      },
      "122": {
        label: "Macedonia",
        value: "Macedonia",
      },
      "123": {
        label: "Madagascar",
        value: "Madagascar",
      },
      "124": {
        label: "Malawi",
        value: "Malawi",
      },
      "125": {
        label: "Malaysia",
        value: "Malaysia",
      },
      "126": {
        label: "Maldives",
        value: "Maldives",
      },
      "127": {
        label: "Mali",
        value: "Mali",
      },
      "128": {
        label: "Malta",
        value: "Malta",
      },
      "129": {
        label: "Marshall Islands",
        value: "Marshall Islands",
      },
      "130": {
        label: "Martinique",
        value: "Martinique",
      },
      "131": {
        label: "Mauritania",
        value: "Mauritania",
      },
      "132": {
        label: "Mauritius",
        value: "Mauritius",
      },
      "133": {
        label: "Mayotte",
        value: "Mayotte",
      },
      "134": {
        label: "Mexico",
        value: "Mexico",
      },
      "135": {
        label: "Micronesia",
        value: "Micronesia",
      },
      "136": {
        label: "Moldova",
        value: "Moldova",
      },
      "137": {
        label: "Monaco",
        value: "Monaco",
      },
      "138": {
        label: "Mongolia",
        value: "Mongolia",
      },
      "139": {
        label: "Montenegro",
        value: "Montenegro",
      },
      "140": {
        label: "Montserrat",
        value: "Montserrat",
      },
      "141": {
        label: "Morocco",
        value: "Morocco",
      },
      "142": {
        label: "Mozambique",
        value: "Mozambique",
      },
      "143": {
        label: "Myanmar",
        value: "Myanmar",
      },
      "144": {
        label: "Namibia",
        value: "Namibia",
      },
      "145": {
        label: "Nauru",
        value: "Nauru",
      },
      "146": {
        label: "Nepal",
        value: "Nepal",
      },
      "147": {
        label: "Netherlands",
        value: "Netherlands",
      },
      "148": {
        label: "New Caledonia",
        value: "New Caledonia",
      },
      "149": {
        label: "New Zealand",
        value: "New Zealand",
      },
      "150": {
        label: "Nicaragua",
        value: "Nicaragua",
      },
      "151": {
        label: "Niger",
        value: "Niger",
      },
      "152": {
        label: "Nigeria",
        value: "Nigeria",
      },
      "153": {
        label: "Norway",
        value: "Norway",
      },
      "154": {
        label: "Northern Mariana Islands",
        value: "Northern Mariana Islands",
      },
      "155": {
        label: "Oman",
        value: "Oman",
      },
      "156": {
        label: "Pakistan",
        value: "Pakistan",
      },
      "157": {
        label: "Palau",
        value: "Palau",
      },
      "158": {
        label: "Palestine",
        value: "Palestine",
      },
      "159": {
        label: "Panama",
        value: "Panama",
      },
      "160": {
        label: "Papua New Guinea",
        value: "Papua New Guinea",
      },
      "161": {
        label: "Paraguay",
        value: "Paraguay",
      },
      "162": {
        label: "Peru",
        value: "Peru",
      },
      "163": {
        label: "Philippines",
        value: "Philippines",
      },
      "164": {
        label: "Pitcairn",
        value: "Pitcairn",
      },
      "165": {
        label: "Poland",
        value: "Poland",
      },
      "166": {
        label: "Portugal",
        value: "Portugal",
      },
      "167": {
        label: "Puerto Rico",
        value: "Puerto Rico",
      },
      "168": {
        label: "Qatar",
        value: "Qatar",
      },
      "169": {
        label: "Romania",
        value: "Romania",
      },
      "170": {
        label: "Russia",
        value: "Russia",
      },
      "171": {
        label: "Rwanda",
        value: "Rwanda",
      },
      "172": {
        label: "Saint Kitts and Nevis",
        value: "Saint Kitts and Nevis",
      },
      "173": {
        label: "Saint Lucia",
        value: "Saint Lucia",
      },
      "174": {
        label: "Saint Vincent and the Grenadines",
        value: "Saint Vincent and the Grenadines",
      },
      "175": {
        label: "Samoa",
        value: "Samoa",
      },
      "176": {
        label: "San Marino",
        value: "San Marino",
      },
      "177": {
        label: "Sao Tome and Principe",
        value: "Sao Tome and Principe",
      },
      "178": {
        label: "Saudi Arabia",
        value: "Saudi Arabia",
      },
      "179": {
        label: "Senegal",
        value: "Senegal",
      },
      "180": {
        label: "Serbia and Montenegro",
        value: "Serbia and Montenegro",
      },
      "181": {
        label: "Seychelles",
        value: "Seychelles",
      },
      "182": {
        label: "Sierra Leone",
        value: "Sierra Leone",
      },
      "183": {
        label: "Singapore",
        value: "Singapore",
      },
      "184": {
        label: "Slovakia",
        value: "Slovakia",
      },
      "185": {
        label: "Slovenia",
        value: "Slovenia",
      },
      "186": {
        label: "Solomon Islands",
        value: "Solomon Islands",
      },
      "187": {
        label: "Somalia",
        value: "Somalia",
      },
      "188": {
        label: "South Africa",
        value: "South Africa",
      },
      "189": {
        label: "South Sudan",
        value: "South Sudan",
      },
      "190": {
        label: "Spain",
        value: "Spain",
      },
      "191": {
        label: "Sri Lanka",
        value: "Sri Lanka",
      },
      "192": {
        label: "Sudan",
        value: "Sudan",
      },
      "193": {
        label: "Suriname",
        value: "Suriname",
      },
      "194": {
        label: "Swaziland",
        value: "Swaziland",
      },
      "195": {
        label: "Sweden",
        value: "Sweden",
      },
      "196": {
        label: "Switzerland",
        value: "Switzerland",
      },
      "197": {
        label: "Syria",
        value: "Syria",
      },
      "198": {
        label: "Taiwan",
        value: "Taiwan",
      },
      "199": {
        label: "Tajikistan",
        value: "Tajikistan",
      },
      "200": {
        label: "Tanzania",
        value: "Tanzania",
      },
      "201": {
        label: "Thailand",
        value: "Thailand",
      },
      "202": {
        label: "Togo",
        value: "Togo",
      },
      "203": {
        label: "Tonga",
        value: "Tonga",
      },
      "204": {
        label: "Trinidad and Tobago",
        value: "Trinidad and Tobago",
      },
      "205": {
        label: "Tunisia",
        value: "Tunisia",
      },
      "206": {
        label: "Turkey",
        value: "Turkey",
      },
      "207": {
        label: "Turkmenistan",
        value: "Turkmenistan",
      },
      "208": {
        label: "Tuvalu",
        value: "Tuvalu",
      },
      "209": {
        label: "Uganda",
        value: "Uganda",
      },
      "210": {
        label: "Ukraine",
        value: "Ukraine",
      },
      "211": {
        label: "United Arab Emirates",
        value: "United Arab Emirates",
      },
      "212": {
        label: "United Kingdom",
        value: "United Kingdom",
      },
      "213": {
        label: "United States",
        value: "United States",
      },
      "214": {
        label: "Uruguay",
        value: "Uruguay",
      },
      "215": {
        label: "Uzbekistan",
        value: "Uzbekistan",
      },
      "216": {
        label: "Vanuatu",
        value: "Vanuatu",
      },
      "217": {
        label: "Vatican City",
        value: "Vatican City",
      },
      "218": {
        label: "Venezuela",
        value: "Venezuela",
      },
      "219": {
        label: "Vietnam",
        value: "Vietnam",
      },
      "220": {
        label: "Virgin Islands, British",
        value: "Virgin Islands, British",
      },
      "221": {
        label: "Virgin Islands, U.S.",
        value: "Virgin Islands, U.S.",
      },
      "222": {
        label: "Yemen",
        value: "Yemen",
      },
      "223": {
        label: "Zambia",
        value: "Zambia",
      },
      "224": {
        label: "Zimbabwe",
        value: "Zimbabwe",
      },
    },
    field_order: "41",
    required: "1",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  uu13w2: {
    id: "436",
    field_key: "uu13w2",
    name: "Emergency Contact Full Name",
    description: "",
    type: "text",
    default_value: "",
    options: "",
    field_order: "43",
    required: "1",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  hx03y2: {
    id: "437",
    field_key: "hx03y2",
    name: "Emergency Contact Number",
    description: "",
    type: "phone",
    default_value: "",
    options: "",
    field_order: "44",
    required: "1",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  s2voc2: {
    id: "438",
    field_key: "s2voc2",
    name: "Will you be staying at the hotel?",
    description: "",
    type: "select",
    default_value: "",
    options: [
      {
        label: "",
        value: "",
      },
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
    field_order: "45",
    required: "1",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  "7ckkp2": {
    id: "444",
    field_key: "7ckkp2",
    name: "When was the last time you attended an MSA Conference?",
    description: "",
    type: "select",
    default_value: "",
    options: [
      {
        label: "",
        value: "",
      },
      {
        label: "This is my first time!",
        value: "This is my first time!",
      },
      {
        label: "2023",
        value: "2023",
      },
      {
        label: "2022",
        value: "2022",
      },
      {
        label: "2021",
        value: "2021",
      },
      {
        label: "2020",
        value: "2020",
      },
      {
        label: "2019",
        value: "2019",
      },
      {
        label: "2018",
        value: "2018",
      },
      {
        label: "2017",
        value: "2017",
      },
      {
        label: "2016",
        value: "2016",
      },
      {
        label: "2015",
        value: "2015",
      },
      {
        label: "2014",
        value: "2014",
      },
      {
        label: "2013",
        value: "2013",
      },
      {
        label: "2012",
        value: "2012",
      },
      {
        label: "2011",
        value: "2011",
      },
      {
        label: "2010",
        value: "2010",
      },
      {
        label: "2009",
        value: "2009",
      },
      {
        label: "2008",
        value: "2008",
      },
      {
        label: "2007",
        value: "2007",
      },
      {
        label: "2006",
        value: "2006",
      },
      {
        label: "2005",
        value: "2005",
      },
      {
        label: "2004",
        value: "2004",
      },
      {
        label: "2003",
        value: "2003",
      },
      {
        label: "2002",
        value: "2002",
      },
      {
        label: "2001",
        value: "2001",
      },
      {
        label: "2000",
        value: "2000",
      },
      {
        label: "Older",
        value: "Older",
      },
    ],
    field_order: "47",
    required: "1",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  atx9k2: {
    id: "445",
    field_key: "atx9k2",
    name: "How did you hear about the Muslim Student Association?",
    description: "",
    type: "select",
    default_value: "",
    options: {
      "0": {
        label: "",
        value: "",
      },
      "1": {
        label: "Friend",
        value: "Friend",
      },
      "2": {
        label: "Social Media",
        value: "Social Media",
      },
      "3": {
        label: "Website",
        value: "Website",
      },
      other_4: "Other",
    },
    field_order: "48",
    required: "1",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  "6icl82": {
    id: "446",
    field_key: "6icl82",
    name: "Which social media platform are you MOST active in?",
    description: "",
    type: "select",
    default_value: "",
    options: [
      {
        label: "",
        value: "",
      },
      {
        label: "None",
        value: "None",
      },
      {
        label: "Facebook",
        value: "Facebook",
      },
      {
        label: "Instagram",
        value: "Instagram",
      },
      {
        label: "Snapchat",
        value: "Snapchat",
      },
      {
        label: "Youtube",
        value: "Youtube",
      },
      {
        label: "Telegram",
        value: "Telegram",
      },
      {
        label: "X (formerly Twitter)",
        value: "X (formerly Twitter)",
      },
      {
        label: "TikTok",
        value: "TikTok",
      },
    ],
    field_order: "49",
    required: "1",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  ms09t2: {
    id: "447",
    field_key: "ms09t2",
    name: "How will you be traveling to the conference this year?",
    description: "",
    type: "select",
    default_value: "",
    options: [
      {
        label: "",
        value: "",
      },
      {
        label: "Flying",
        value: "Flying",
      },
      {
        label: "Driving",
        value: "Driving",
      },
      {
        label: "I live here",
        value: "I live here",
      },
    ],
    field_order: "50",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  "18a01": {
    id: "466",
    field_key: "18a01",
    name: "Do you or members of your group have any food allergies that we should know about?",
    description: "",
    type: "textarea",
    default_value: "",
    options: "",
    field_order: "52",
    required: "0",
    form_id: "13",
    created_at: "2024-10-16 05:55:57",
  },
  m2jup: {
    id: "467",
    field_key: "m2jup",
    name: "Are there any special accommodations that you need?",
    description:
      "Though we will try our best to make this experience the best possible for you and your group, we cannot guarantee that all requests will be met.",
    type: "textarea",
    default_value: "",
    options: "",
    field_order: "54",
    required: "0",
    form_id: "13",
    created_at: "2024-10-16 05:56:47",
  },
  "2o4o12": {
    id: "442",
    field_key: "2o4o12",
    name: "Total",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "56",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  "9qa9n2": {
    id: "443",
    field_key: "9qa9n2",
    name: "Next: Bazaar",
    description: "",
    type: "break",
    default_value: "",
    options: "",
    field_order: "58",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  ky3yv: {
    id: "468",
    field_key: "ky3yv",
    name: "HTML",
    description:
      '<h3 style="font-family:Ubuntu,Open Sans,Tahoma,sans-serif;font-weight:bold;color: #e5e1b7;">Bazaar Table Rental</h3>\r\n<p style="font-size: 1.25em;line-height:1.75em">If you are interested in renting a table to sell items at the conference bazaar, please indicate how many tables you would like to request. Please note that all table requests are subject to approval and availability.</p>',
    type: "html",
    default_value: "",
    options: "",
    field_order: "60",
    required: "0",
    form_id: "13",
    created_at: "2024-10-16 06:06:41",
  },
  ydkc02: {
    id: "440",
    field_key: "ydkc02",
    name: "Will you be needing a table for bazaar?",
    description: "",
    type: "select",
    default_value: "",
    options: [
      {
        label: "",
        value: "",
      },
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
    field_order: "62",
    required: "1",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  afp6e: {
    id: "465",
    field_key: "afp6e",
    name: "How many tables do you need? ($25/per table)",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "64",
    required: "1",
    form_id: "13",
    created_at: "2024-10-16 05:47:06",
  },
  "2wbub2": {
    id: "441",
    field_key: "2wbub2",
    name: "Please tell us more about the items you wish to sell",
    description: "",
    type: "textarea",
    default_value: "",
    options: "",
    field_order: "66",
    required: "1",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  ehbc02: {
    id: "448",
    field_key: "ehbc02",
    name: "Total",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "68",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  "2gye42": {
    id: "449",
    field_key: "2gye42",
    name: "Next: Options",
    description: "",
    type: "break",
    default_value: "",
    options: "",
    field_order: "70",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  yenxx: {
    id: "469",
    field_key: "yenxx",
    name: "HTML",
    description:
      '<h3 style="font-family: Ubuntu,Open Sans,Tahoma,sans-serif; font-weight: bold; color: #e5e1b7;">Support the Conference with a Donation</h3>\r\n<p style="font-size:1.25em;line-height: 1.75em;">To better accommodate all our valued guests, we have reduced registration fees to below cost. The success and sustainability of the conference rely on the generosity of donors like you. Please consider making a donation to help cover the remaining expenses and ensure the event continues to thrive. Your support makes a significant difference!</p>',
    type: "html",
    default_value: "",
    options: "",
    field_order: "72",
    required: "0",
    form_id: "13",
    created_at: "2024-10-16 06:11:56",
  },
  avjwx2: {
    id: "450",
    field_key: "avjwx2",
    name: "Would you like to make a donation with your payment?",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "74",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  f0k4a2: {
    id: "451",
    field_key: "f0k4a2",
    name: "Total",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "76",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  yt4eg2: {
    id: "452",
    field_key: "yt4eg2",
    name: "Next: Review",
    description: "",
    type: "break",
    default_value: "",
    options: "",
    field_order: "78",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  "74uo73": {
    id: "453",
    field_key: "74uo73",
    name: "Review",
    description: "",
    type: "summary",
    default_value: "",
    options: "",
    field_order: "80",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
  "982gs": {
    id: "470",
    field_key: "982gs",
    name: "Subtotal",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "82",
    required: "0",
    form_id: "13",
    created_at: "2024-10-16 07:11:14",
  },
  d0pzt: {
    id: "471",
    field_key: "d0pzt",
    name: "Family Discount",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "84",
    required: "0",
    form_id: "13",
    created_at: "2024-10-16 07:11:32",
  },
  "19dow": {
    id: "472",
    field_key: "19dow",
    name: "Family Discount",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "86",
    required: "0",
    form_id: "13",
    created_at: "2024-10-16 07:11:45",
  },
  p2l9j: {
    id: "473",
    field_key: "p2l9j",
    name: "Total",
    description: "",
    type: "number",
    default_value: "",
    options: "",
    field_order: "88",
    required: "0",
    form_id: "13",
    created_at: "2024-10-16 07:11:59",
  },
  fmz5v3: {
    id: "454",
    field_key: "fmz5v3",
    name: "Paid?",
    description: "",
    type: "hidden",
    default_value: "No",
    options: "",
    field_order: "90",
    required: "0",
    form_id: "13",
    created_at: "2024-10-13 21:56:57",
  },
};
