import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const LINE_COLOR = "#1b3356";

export default function AgeDistributionGraph() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Calculate age distribution by individual age
  const ageDistribution = entries.reduce((acc, entry) => {
    if (entry.meta.Registrants) {
      Object.values(entry.meta.Registrants)
        .filter((registrant) => registrant && registrant["i6b3v3"]) // Assuming i6b3v3 is the age field
        .forEach((registrant) => {
          const age = parseInt(registrant["i6b3v3"], 10);
          if (!isNaN(age)) {
            acc[age] = (acc[age] || 0) + 1;
          }
        });
    }
    return acc;
  }, {} as { [age: number]: number });

  const ageData = Object.entries(ageDistribution).map(([age, count]) => ({
    name: age,
    count: count,
  }));

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-2">
      <h1 className="text-xl text-primary text-center">Age Distribution</h1>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={600} height={300} data={ageData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            label={{ value: "Age", position: "insideBottom", offset: -5 }}
          />
          <YAxis
            label={{ value: "Count", angle: -90, position: "insideLeft" }}
          />
          <Tooltip />
          <Legend />
          <Line
            type="linear"
            dataKey="count"
            stroke={LINE_COLOR}
            dot={{ stroke: LINE_COLOR, strokeWidth: 2, r: 3 }}
            activeDot={{ r: 5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
