import { Fragment } from "react";
import InternalPage from "../../../components/layout/internal/InternalPage";
import { classNames } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Link } from "react-router-dom";

export default function AllRegistrationsPage() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  return (
    <InternalPage>
      <div className="px-0 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold text-gray-900">
              All Registrants
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the registrants who have paid including their name,
              address, email and other registration information.
            </p>
          </div>
        </div>
        <div className="mt-8 flow-root rounded-md bg-white">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
              <table className="min-w-full">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Gender
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Age
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Programs
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                    >
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {entries
                    .slice()
                    .reverse()
                    .map((entry) => (
                      <Fragment key={entry.item_key}>
                        <tr className="border-t border-gray-200">
                          <th
                            scope="colgroup"
                            colSpan={6}
                            className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                          >
                            {entry.item_key}
                          </th>
                        </tr>
                        {/* Ensure Registrants is defined and filter out undefined registrants */}
                        {Object.entries(entry.meta.Registrants)
                          .filter(([key]) => key !== "form")
                          .map(([_, registrant], registrantIdx) => (
                            <tr
                              key={registrantIdx}
                              className={classNames(
                                registrantIdx === 0
                                  ? "border-gray-300"
                                  : "border-gray-200",
                                "border-t"
                              )}
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                {`${registrant.s7ztq3 || ""} ${
                                  registrant.pu0bc3 || ""
                                }`}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {registrant["85tfd3"] || ""}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {registrant.i6b3v3 || ""}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {registrant.jgfy3 || ""}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {registrant.bpc1r3 || ""}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                <Link
                                  to={"/registrant/" + entry.item_key}
                                  className="text-primary hover:text-secondary"
                                >
                                  View
                                  <span className="sr-only">
                                    , {registrant["85tfd3"] || ""}
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </InternalPage>
  );
}
