import InternalPage from "../../../components/layout/internal/InternalPage";
import ProfileSettingsForm from "./ProfileSettingsForm";
import ResetPasswordForm from "./ResetPasswordForm";

export default function UserSettingsPage() {
  return (
    <InternalPage>
      <ProfileSettingsForm />
      <ResetPasswordForm />
    </InternalPage>
  );
}
