import { FormEntry } from "../../../types/types";

export default function SurveyResponseCard(props: {
  entry: FormEntry;
  className: string;
}) {
  return (
    <div
      className={`overflow-hidden bg-white shadow rounded-lg col-span-2 ${props.className}`}
    >
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base/7 font-semibold text-gray-900">
          General Info
        </h3>
        <p className="mt-1 max-w-2xl text-sm/6 text-gray-500">
          General Information of the group.
        </p>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Phone Number</dt>
            <dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
              <a
                href={`tel:${props.entry.meta["Phone"]}`}
                target="_blank"
                rel="noreferrer"
              >
                {props.entry.meta["Phone"]}
              </a>
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Ethnicity</dt>
            <dd className="mt-1 text-md/6 text-gray-700 sm:col-span-2 sm:mt-0">
              {Array.isArray(props.entry.meta["Ethnicity"]) ? (
                props.entry.meta["Ethnicity"].map((ethnicity, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 mr-3 text-md font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
                  >
                    {ethnicity}
                  </span>
                ))
              ) : (
                <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-md font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                  {props.entry.meta["Ethnicity"]}
                </span>
              )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">
              Staying at Hotel?
            </dt>
            <dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
              {props.entry.meta["Will you be staying at the hotel?"]}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">
              Last Attendance
            </dt>
            <dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
              {
                props.entry.meta[
                  "When was the last time you attended an MSA Conference?"
                ]
              }
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">
              Heard About Conference
            </dt>
            <dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
              {
                props.entry.meta[
                  "How did you hear about the Muslim Student Association?"
                ]
              }
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">
              Getting to Conference by
            </dt>
            <dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
              {
                props.entry.meta[
                  "How will you be traveling to the conference this year?"
                ]
              }
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">
              Most Active Social Media
            </dt>
            <dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
              {
                props.entry.meta[
                  "Which social media platform are you MOST active in?"
                ]
              }
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">
              Food Allergies
            </dt>
            <dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
              {props.entry.meta[
                "Do you or members of your group have any food allergies that we should know about?"
              ] ? (
                props.entry.meta[
                  "Do you or members of your group have any food allergies that we should know about?"
                ]
              ) : (
                <p className="italic">None</p>
              )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">
              Special Accommodations
            </dt>
            <dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
              {props.entry.meta[
                "Are there any special accommodations that you need?"
              ] ? (
                props.entry.meta[
                  "Are there any special accommodations that you need?"
                ]
              ) : (
                <p className="italic">None</p>
              )}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
