import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { generateColorFromName } from "../../../utils/utils";

export default function EthnicityDistributionGraph() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Calculate ethnicity distribution without flattening
  const ethnicityDistribution = entries.reduce((acc, entry) => {
    const ethnicities = entry.meta.Ethnicity;
    if (ethnicities) {
      // Handle both array and string cases for Ethnicity
      if (Array.isArray(ethnicities)) {
        ethnicities.forEach((ethnicity) => {
          acc[ethnicity] = (acc[ethnicity] || 0) + 1;
        });
      } else if (typeof ethnicities === "string") {
        acc[ethnicities] = (acc[ethnicities] || 0) + 1;
      }
    }
    return acc;
  }, {} as { [ethnicity: string]: number });

  const ethnicityData = Object.entries(ethnicityDistribution).map(
    ([ethnicity, count]) => ({
      name: ethnicity,
      value: count,
    })
  );

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-2">
      <h1 className="text-xl text-primary text-center">
        Ethnicity Distribution
      </h1>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie
            data={ethnicityData}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#1b3356"
            label={({ name, value }) => `${name}: ${value}`}
          >
            {ethnicityData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={generateColorFromName(entry.name)}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
