import { FormEntry } from "../../../types/types";

export default function EmergencyInfoCard(props: { entry: FormEntry }) {
  return (
    <div className="overflow-hidden bg-white shadow rounded-lg col-span-2">
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base/7 font-semibold text-gray-900">
          Emergency Contact Info
        </h3>
        <p className="mt-1 max-w-2xl text-sm/6 text-gray-500">
          Emergency contact information of the group.
        </p>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Contact Name</dt>
            <dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
              {props.entry.meta["Emergency Contact Full Name"]}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Phone Number</dt>
            <dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
              <a
                href={`tel:${props.entry.meta["Emergency Contact Number"]}`}
                target="_blank"
                rel="noreferrer"
              >
                {props.entry.meta["Emergency Contact Number"]}
              </a>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
