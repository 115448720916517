import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export default function EthnicityDistributionTable() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Calculate ethnicity distribution without flattening
  const ethnicityDistribution = entries.reduce((acc, entry) => {
    const ethnicities = entry.meta.Ethnicity;
    if (ethnicities) {
      // Handle both array and string cases for Ethnicity
      if (Array.isArray(ethnicities)) {
        ethnicities.forEach((ethnicity) => {
          acc[ethnicity] = (acc[ethnicity] || 0) + 1;
        });
      } else if (typeof ethnicities === "string") {
        acc[ethnicities] = (acc[ethnicities] || 0) + 1;
      }
    }
    return acc;
  }, {} as { [ethnicity: string]: number });

  const ethnicitiesData = Object.entries(ethnicityDistribution).map(
    ([ethnicity, count]) => ({
      name: ethnicity,
      value: count,
    })
  );

  return (
    <div className="overflow-y-scroll no-scrollbar rounded-lg bg-white shadow">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Ethnicity
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Count
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {ethnicitiesData.map((ethnicityData, idx) => (
            <tr key={idx}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {ethnicityData.name}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {ethnicityData.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
