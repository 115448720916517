import { useState } from "react";
import InternalPage from "../../../components/layout/internal/InternalPage";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../store";
import {
  selectEntryByKey,
  fetchFormEntries,
} from "../../../store/slices/formEntriesSlice";
import { checkInRegistrants } from "../../../services/apiService";
import { useNotification } from "../../../context/NotificationContext";

export default function CheckInPage() {
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { registrantKey } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const [selectedRegistrantKeys, setSelectedRegistrantKeys] = useState<
    string[]
  >([]);

  const entry = useSelector((state: RootState) =>
    selectEntryByKey(state, registrantKey || "")
  );

  const updateEntries = async () => {
    await dispatch(fetchFormEntries());
  };

  const handleRegistrantClick = (key: string) => {
    const sanitizedKey = key.startsWith("i") ? key.slice(1) : key;

    setSelectedRegistrantKeys((prevKeys) =>
      prevKeys.includes(sanitizedKey)
        ? prevKeys.filter((k) => k !== sanitizedKey)
        : [...prevKeys, sanitizedKey]
    );
  };

  const handleSelectAll = () => {
    if (entry) {
      const allKeys = Object.keys(entry.meta.Registrants)
        .filter((key) => key !== "form")
        .map((key) => (key.startsWith("i") ? key.slice(1) : key));

      setSelectedRegistrantKeys(
        selectedRegistrantKeys.length === allKeys.length ? [] : allKeys
      );
    }
  };

  const handleCheckIn = async () => {
    if (entry && selectedRegistrantKeys.length > 0) {
      try {
        await checkInRegistrants(selectedRegistrantKeys, entry.id);
        showNotification({
          title: "Successfully Checked-In!",
          message: `Successfully checked-in ${selectedRegistrantKeys.length} guests.`,
          type: "success",
        });
        await updateEntries();
        navigate(-1);
      } catch (error) {
        showNotification({
          title: "Checked-In Failed",
          message: `Failed to check in ${selectedRegistrantKeys.length} guests.`,
          type: "error",
        });
        console.error("Failed to check in registrants:", error);
      }
    } else {
      showNotification({
        title: "Nothing Selected",
        message: `No registrants selected for check-in.`,
        type: "info",
      });
    }
  };

  return (
    <InternalPage>
      {entry ? (
        <>
          <section aria-labelledby="general-information-title">
            <div className="mb-5 md:flex md:items-center md:justify-between md:space-x-5">
              <div className="flex items-center space-x-5">
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">
                    Check-In Registrants
                  </h1>
                </div>
              </div>
              <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                <button
                  onClick={handleSelectAll}
                  className="inline-flex items-center justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                >
                  {selectedRegistrantKeys.length ===
                  Object.keys(entry.meta.Registrants).length - 1
                    ? "Deselect All"
                    : "Select All"}
                </button>
              </div>
            </div>
          </section>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {Object.entries(entry.meta.Registrants)
              .filter(([key]) => key !== "form")
              .map(([key, registrant], registrantIdx) => {
                const sanitizedKey = key.startsWith("i") ? key.slice(1) : key;
                const isSelected =
                  selectedRegistrantKeys.includes(sanitizedKey);

                return (
                  <div
                    key={registrantIdx}
                    onClick={() => handleRegistrantClick(key)}
                    className={`relative flex items-center space-x-3 rounded-lg border px-6 py-5 shadow-sm transition-all cursor-pointer ${
                      isSelected
                        ? "border-primary bg-blue-50"
                        : "border-gray-300 bg-white"
                    }`}
                  >
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={(e) => e.stopPropagation()} // Prevent click from triggering div onClick
                      className="mr-3 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                    />
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-medium text-gray-900">
                        {`${registrant.s7ztq3} ${registrant.pu0bc3}`}
                        {registrant.opjkc3 === "true" ? (
                          <span className="ml-3 inline-flex float-right items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            Checked-In
                          </span>
                        ) : (
                          <span className="ml-3 inline-flex float-right items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                            Not Checked-In
                          </span>
                        )}
                      </p>
                      <p className="truncate text-sm text-gray-500">
                        {registrant.jgfy3}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
          <div>
            <button
              onClick={handleCheckIn}
              disabled={selectedRegistrantKeys.length === 0}
              className="mt-5 w-full items-center justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary disabled:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
            >
              Check-In{" "}
              {selectedRegistrantKeys.length > 0 &&
                `${selectedRegistrantKeys.length} guests`}
            </button>
          </div>
        </>
      ) : (
        <p>No entry found with the provided key.</p>
      )}
    </InternalPage>
  );
}
