import { FormEntry } from "../../../types/types";

export default function PaymentInfoCard(props: { entry: FormEntry }) {
  // Convert registration fees to numbers and sum them, then round to two decimal places as a number
  const registrationFees = Number(
    props.entry.meta["5yusq3-value"]
      .reduce((a: number, b: string) => a + Number(b), 0)
      .toFixed(2)
  );

  // Calculate family discount based on registration fees and round to two decimal places
  const familyDiscount =
    props.entry.meta["Number of Registrants"] >= 4
      ? Number((registrationFees * 0.12).toFixed(2))
      : 0;

  // Ensure donation is a number, or default to 0
  const donation = Number(props.entry.meta["avjwx2-value"]) || 0;

  // Parse the number of tables needed as a number
  const numberOfTablesNeeded =
    Number(props.entry.meta["How many tables do you need? ($25/per table)"]) ||
    0;

  // Calculate bazaar table fees and round to two decimal places
  const bazaarTableFees = Number((numberOfTablesNeeded * 25).toFixed(2));

  // Calculate the total and round to two decimal places
  const total = Number(
    (registrationFees - familyDiscount + donation + bazaarTableFees).toFixed(2)
  );

  return (
    <>
      <h2 className="sr-only">Payment Summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="flex-auto pl-6 pt-6">
            <dt className="text-sm/6 font-semibold text-gray-900">Amount</dt>
            <dd className="mt-1 text-xl font-semibold text-gray-900">
              ${props.entry.meta["p2l9j-value"]}
            </dd>
          </div>
          <div className="flex-none self-end px-6 pt-4">
            <dt className="sr-only">Status</dt>
            {props.entry.meta["Paid?"] === "Yes" ? (
              <dd className="rounded-md bg-green-50 px-2 py-1 text-md font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                Paid
              </dd>
            ) : (
              <dd className="rounded-md bg-red-50 px-2 py-1 text-md font-medium text-red-600 ring-1 ring-inset ring-red-600/20">
                Not Paid
              </dd>
            )}
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
            <table className="w-full whitespace-nowrap text-left text-sm/6">
              <colgroup>
                <col className="w-3/4" />
                <col className="w-1/4" />
              </colgroup>
              <tbody>
                <tr>
                  <td className="max-w-0 px-0 py-1 align-top">
                    <div className="truncate font-medium text-gray-900">
                      Registration Fees
                    </div>
                    <div className="truncate text-gray-500">
                      {props.entry.meta.Registration.join(", ")}
                    </div>
                  </td>
                  <td className="py-1 pr-0 text-lg text-right align-top tabular-nums text-gray-700">
                    ${registrationFees.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="max-w-0 px-0 py-1 align-top">
                    <div className="truncate font-medium text-gray-900">
                      Family Discount
                    </div>
                    <div className="truncate text-gray-500">
                      Group has {props.entry.meta["Number of Registrants"]}{" "}
                      registrants
                    </div>
                  </td>
                  <td className="py-1 pl-8 pr-0 text-lg text-right align-top tabular-nums text-red-700">
                    -${familyDiscount.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="max-w-0 px-0 py-1 align-top">
                    <div className="truncate font-medium text-gray-900">
                      Optional Donation
                    </div>
                  </td>
                  <td className="py-1 pl-8 pr-0 text-lg text-right align-top tabular-nums text-gray-700">
                    ${donation.toFixed(2)}
                  </td>
                </tr>
                <tr className="border-b border-gray-100">
                  <td className="max-w-0 px-0 py-1 align-top">
                    <div className="truncate font-medium text-gray-900">
                      Bazaar Table Fees
                    </div>
                    <div className="truncate text-gray-500">
                      $25/table x {numberOfTablesNeeded} tables
                    </div>
                  </td>
                  <td className="py-1 pl-8 pr-0 text-lg text-right align-top tabular-nums text-gray-700">
                    ${bazaarTableFees.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="max-w-0 px-0 py-5 align-top">
                    <div className="truncate font-medium text-gray-900">
                      Total
                    </div>
                  </td>
                  <td className="py-5 pl-8 pr-0 text-lg font-bold text-right align-top tabular-nums text-gray-700">
                    ${total.toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </dl>
        {/* <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
              <a href="#" className="text-sm/6 font-semibold text-gray-900">
                Download receipt <span aria-hidden="true">&rarr;</span>
              </a>
            </div> */}
      </div>
    </>
  );
}
