import { FormEntry } from "../../../types/types";
import EmergencyInfoCard from "./EmergencyInfoCard";
import PaymentInfoCard from "./PaymentInfoCard";
import SurveyResponseCard from "./SurveyResponseCard";

export default function GeneralInfoSection(props: { entry: FormEntry }) {
  return (
    <section aria-labelledby="general-information-title">
      <div className="flex flex-col sm:flex-row gap-x-0 gap-y-5 sm:gap-x-5 sm:gap-y-5">
        <SurveyResponseCard entry={props.entry} className="basis-2/3" />
        <div className="flex flex-col gap-y-5 basis-1/3">
          <PaymentInfoCard entry={props.entry} />
          <EmergencyInfoCard entry={props.entry} />
        </div>
      </div>
    </section>
  );
}
