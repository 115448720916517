import { FormEntry } from "../../../types/types";
import { formatDate } from "../../../utils/utils";
import { Link } from "react-router-dom";

export default function RegistrantHeader(props: { entry: FormEntry }) {
  return (
    <section aria-labelledby="general-information-title">
      <div className="mb-8 md:flex md:items-center md:justify-between md:space-x-5">
        <div className="flex items-center space-x-5">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">
              Confirmation #: {props.entry?.item_key}
            </h1>
            <p className="text-sm font-medium text-gray-500">
              Registered on{" "}
              <time dateTime={props.entry?.created_at}>
                {" "}
                {formatDate(props.entry?.created_at)}
              </time>
            </p>
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          <Link
            to={`/check-in/${props.entry?.item_key}`}
            className="inline-flex items-center justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
          >
            Check-In
          </Link>
        </div>
      </div>
    </section>
  );
}
