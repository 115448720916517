import { useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useNavigate } from "react-router-dom";

export default function QRCodeScanPage() {
  const navigate = useNavigate();
  const [stopStream, setStopStream] = useState(false);

  const handleScan = (err: unknown, result?: unknown) => {
    // Type assertion to access `text` on `Result`
    const text = (result as any)?.text;
    if (text) {
      navigate(`/registrant/${text}`);
    }
  };

  const dismissQrReader = () => {
    setStopStream(true);
    navigate(-1);
  };

  return (
    <div className="flex flex-col h-dvh bg-black items-center justify-center">
      <BarcodeScannerComponent
        onUpdate={handleScan}
        facingMode="environment"
        stopStream={stopStream}
      />
      <div className="w-full m-3 bg-black">
        <button
          onClick={dismissQrReader}
          className="w-full items-center justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
        >
          Close
        </button>
      </div>
    </div>
  );
}
