import { FormEntry } from "../../../types/types";

export default function BazaarTableCard(props: {
  entry: FormEntry;
  className?: string;
}) {
  const needTable =
    props.entry.meta["Will you be needing a table for bazaar?"] === "Yes";
  // Parse the number of tables needed as a number
  const numberOfTablesNeeded =
    Number(props.entry.meta["How many tables do you need? ($25/per table)"]) ||
    0;

  // Calculate bazaar table fees and round to two decimal places
  const bazaarTableFees = Number((numberOfTablesNeeded * 25).toFixed(2));

  return (
    <div
      className={`overflow-hidden bg-white shadow rounded-lg ${props.className}`}
    >
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base/7 font-semibold text-gray-900">
          Bazaar Table
        </h3>
        <p className="mt-1 max-w-2xl text-sm/6 text-gray-500">
          Details relating to bazaar table accommodations.
        </p>
      </div>
      {needTable ? (
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Number of Tables
              </dt>
              <dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
                {numberOfTablesNeeded}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Total Cost</dt>
              <dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
                ${bazaarTableFees.toFixed(2)}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Type of Items Being Sold
              </dt>
              <dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
                {
                  props.entry.meta[
                    "Please tell us more about the items you wish to sell"
                  ]
                }
              </dd>
            </div>
          </dl>
        </div>
      ) : (
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <p className="italic text-gray-600">No table(s) needed</p>
            </div>
          </dl>
        </div>
      )}
    </div>
  );
}
