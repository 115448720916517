import { useState } from "react";
import { updatePassword } from "firebase/auth";
import { getAuth } from "firebase/auth";
import ErrorAlert from "../../../components/common/ErrorAlert";
import SuccessAlert from "../../../components/common/SuccessAlert";

export default function ResetPasswordForm() {
  const auth = getAuth();
  const user = auth.currentUser;

  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    // Check if passwords match
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    // Ensure user is authenticated
    if (user) {
      try {
        await updatePassword(user, newPassword);
        setSuccessMessage("Password reset successfully.");
      } catch (error) {
        console.error("Error resetting password:", error);
        setErrorMessage("Failed to reset password. Please try again.");
      }
    } else {
      setErrorMessage("User not authenticated. Please log in again.");
    }
  };

  return (
    <div className="mt-5 gap-x-0 sm:gap-x-5 gap-y-5 rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      {successMessage && (
        <SuccessAlert
          message={successMessage}
          onClose={() => setSuccessMessage("")}
        />
      )}
      {errorMessage && (
        <ErrorAlert
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}

      <form onSubmit={handleResetPassword}>
        <div className="space-y-12 sm:space-y-16">
          <div>
            <h2 className="text-base/7 font-semibold text-gray-900">
              Reset Password
            </h2>
            <p className="mt-1 max-w-2xl text-sm/6 text-gray-600">
              Use this form to reset your password
            </p>

            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="newPassword"
                  className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5"
                >
                  New Password
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary sm:max-w-md">
                    <input
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      autoComplete="new-password"
                      className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="confirmNewPassword"
                  className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5"
                >
                  Confirm New Password
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary sm:max-w-md">
                    <input
                      id="confirmNewPassword"
                      name="confirmNewPassword"
                      type="password"
                      autoComplete="new-password"
                      className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            className="inline-flex justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
          >
            Reset
          </button>
        </div>
      </form>
    </div>
  );
}
