// context/NotificationContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import NotificationAlert from "../components/common/NotificationAlert";

interface NotificationData {
  title: string;
  message: string;
  type?: "success" | "error" | "info";
}

interface NotificationContextType {
  showNotification: (notification: NotificationData) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [notification, setNotification] = useState<NotificationData | null>(
    null
  );
  const [isVisible, setIsVisible] = useState(false);

  const showNotification = (notification: NotificationData) => {
    setNotification(notification);
    setIsVisible(true);
  };

  const hideNotification = () => setIsVisible(false);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => setIsVisible(false), 5000); // Auto-dismiss after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      {notification && (
        <NotificationAlert
          notification={notification}
          isVisible={isVisible}
          onClose={hideNotification}
        />
      )}
    </NotificationContext.Provider>
  );
};
