// Notification.tsx
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import React from "react";

interface NotificationProps {
  notification: {
    title: string;
    message: string;
    type?: "success" | "error" | "info";
  };
  isVisible: boolean;
  onClose: () => void;
}

export default function NotificationAlert(props: NotificationProps) {
  const iconColor =
    props.notification.type === "success"
      ? "text-green-400"
      : props.notification.type === "error"
      ? "text-red-400"
      : "text-blue-400";

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed top-12 inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={props.isVisible}
          enter="transform transition ease-out duration-300"
          enterFrom="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2"
          enterTo="opacity-100 translate-y-0 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="flex items-start">
                <div className="shrink-0">
                  <CheckCircleIcon
                    aria-hidden="true"
                    className={`h-6 w-6 ${iconColor}`}
                  />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">
                    {props.notification.title}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    {props.notification.message}
                  </p>
                </div>
                <div className="ml-4 flex shrink-0">
                  <button
                    type="button"
                    onClick={props.onClose}
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
