import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export default function TotalRegistrantCount() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Calculate the aggregate of "Number of Registrants" from each entry's meta field
  const totalRegistrants = entries.reduce((total, entry) => {
    const numberOfRegistrants = parseInt(
      entry.meta["Number of Registrants"] || "0",
      10
    );
    return total + (isNaN(numberOfRegistrants) ? 0 : numberOfRegistrants);
  }, 0);

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500">
        Total Registrants
      </dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        {totalRegistrants}
      </dd>
    </div>
  );
}
