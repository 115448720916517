import "./App.css";
import { firebaseConfig } from "./config/firebase";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { BrowserRouter } from "react-router-dom";
import Routes from "./components/Routes";
import { NotificationProvider } from "./context/NotificationContext";

function App() {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <BrowserRouter>
      <NotificationProvider>
        <Routes />
      </NotificationProvider>
    </BrowserRouter>
  );
}

export default App;
