import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import InternalPage from "../../../components/layout/internal/InternalPage";
import { selectEntryByKey } from "../../../store/slices/formEntriesSlice";
import { RootState } from "../../../store";
import RegistrantCard from "./RegistrantCard";
import GeneralInfoSection from "./GeneralInfoSection";
import RegistrantHeader from "./RegistrantHeader";
import BazaarTableCard from "./BazaarTableCard";

export default function RegistrantPage() {
  const { registrantKey } = useParams();

  const entry = useSelector((state: RootState) =>
    selectEntryByKey(state, registrantKey || "")
  );

  return (
    <InternalPage>
      {entry ? (
        <>
          <RegistrantHeader entry={entry} />
          <div className="grid grid-cols-1 gap-x-0 sm:gap-x-5 gap-y-5">
            {Object.entries(entry.meta.Registrants)
              .filter(([key]) => key !== "form")
              .map(([_, registrant], registrantIdx) => (
                <RegistrantCard
                  key={registrantIdx}
                  registrant={registrant}
                  idx={registrantIdx}
                />
              ))}
            <GeneralInfoSection entry={entry} />
            <BazaarTableCard entry={entry} />
          </div>
        </>
      ) : (
        <p>No entry found with the provided key.</p>
      )}
    </InternalPage>
  );
}
