import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export default function CheckInCount() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Calculate the number of "Checked In" values that are true
  const totalCheckedIn = entries.reduce((total, entry) => {
    const checkedInArray = entry.meta["Checked In"] || [];
    const countTrue = checkedInArray.filter(
      (value: string) => value === "true"
    ).length;
    return total + countTrue;
  }, 0);

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500">Checked In</dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        {totalCheckedIn}
      </dd>
    </div>
  );
}
