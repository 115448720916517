export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function formatDate(dateString: string | Date | undefined): string {
  if (!dateString) {
    return ""; // Return empty string if date is undefined
  }

  // Parse the date string as UTC
  const utcDate =
    typeof dateString === "string"
      ? new Date(dateString.replace(" ", "T") + "Z") // Replace space with 'T' and add 'Z' to indicate UTC
      : dateString;

  // Convert the UTC date to Los Angeles time
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "America/Los_Angeles",
    month: "long", // Full month name (e.g., "January")
    day: "numeric", // Day of the month
    year: "numeric", // Full year
    hour: "numeric", // Hour without leading zero
    minute: "2-digit", // Minute with leading zero if needed
    hour12: true, // 12-hour format with AM/PM
  };

  // Format date in Los Angeles timezone
  const laDateString = new Intl.DateTimeFormat("en-US", options).format(
    utcDate
  );

  // Get the timezone offset in hours (e.g., -8 for PST, -7 for PDT)
  const offset = utcDate.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
    timeZoneName: "short",
  });
  const timezoneAbbreviation = offset.split(" ").pop(); // Get the last part, which is the abbreviation (e.g., PST or PDT)

  return `${laDateString} ${timezoneAbbreviation}`;
}

// Function to generate a random color in hexadecimal format
export function getRandomColor() {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, "0")}`;
}

// Function to generate a consistent color from a name
export function generateColorFromName(name: string) {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  // Convert hash to hexadecimal color code
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, "0")}${(
    (hash >> 16) &
    0xff
  )
    .toString(16)
    .padStart(2, "0")}${((hash >> 8) & 0xff).toString(16).padStart(2, "0")}`;
  return color;
}
