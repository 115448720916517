import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

const COLORS = ["#1b3356", "#de80c2"];

export default function GenderDistributionGraph() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  const genderDistribution = entries.reduce((acc, entry) => {
    if (entry.meta.Registrants) {
      Object.values(entry.meta.Registrants)
        .filter((registrant) => registrant && registrant["85tfd3"])
        .forEach((registrant) => {
          const gender = registrant["85tfd3"];
          acc[gender] = (acc[gender] || 0) + 1;
        });
    }
    return acc;
  }, {} as { [gender: string]: number });

  const data = Object.entries(genderDistribution).map(([gender, count]) => ({
    name: gender,
    value: count,
  }));

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-2">
      <h1 className="text-xl text-primary text-center">Gender Distribution</h1>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#1b3356"
            label={({ name, value }) => `${name}: ${value}`}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
