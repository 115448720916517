import { Route, Routes as Rts } from "react-router-dom";
import SignedInRoute from "./auth/SignedInRoute";
import PrivateRoute from "../components/auth/PrivateRoute";
import SignInPage from "../pages/auth/SignInPage";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage";
import ForbiddenPage from "../pages/error/403ForbiddenPage";
import NotFoundPage from "../pages/error/404NotFoundPage";
import DashboardPage from "../pages/internal/DashboardPage/DashboardPage";
import UserSettingsPage from "../pages/internal/UserSettingsPage/UserSettingsPage";
import ReportsPage from "../pages/internal/ReportsPage/ReportsPage";
import TeamPage from "../pages/internal/TeamPage/TeamPage";
import AllRegistrationsPage from "../pages/internal/AllRegistrationsPage/AllRegistrationsPage";
import RegistrantPage from "../pages/internal/RegistrantPage/RegistrantPage";
import QRCodeScanPage from "../pages/internal/QRCodeScanPage/QRCodeScanPage";
import CheckInPage from "../pages/internal/CheckInPage/CheckInPage";

export default function Routes() {
  return (
    <Rts>
      {/* Internal Pages */}
      <Route
        index
        path=""
        element={
          <SignedInRoute>
            <DashboardPage />
          </SignedInRoute>
        }
      />
      <Route
        index
        path="all-registrations"
        element={
          <SignedInRoute>
            <AllRegistrationsPage />
          </SignedInRoute>
        }
      />
      <Route
        index
        path="registrant/:registrantKey"
        element={
          <SignedInRoute>
            <RegistrantPage />
          </SignedInRoute>
        }
      />
      <Route
        index
        path="scan"
        element={
          <SignedInRoute>
            <QRCodeScanPage />
          </SignedInRoute>
        }
      />
      <Route
        index
        path="check-in/:registrantKey"
        element={
          <SignedInRoute>
            <CheckInPage />
          </SignedInRoute>
        }
      />
      {/* <Route
        index
        path="team"
        element={
          <SignedInRoute>
            <TeamPage />
          </SignedInRoute>
        }
      /> */}
      <Route
        index
        path="reports"
        element={
          <SignedInRoute>
            <ReportsPage />
          </SignedInRoute>
        }
      />
      <Route
        index
        path="settings"
        element={
          <SignedInRoute>
            <UserSettingsPage />
          </SignedInRoute>
        }
      />

      {/* External Pages */}
      <Route path="sign-in" element={<SignInPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />

      {/* Misc Routes */}
      <Route path="forbidden" element={<ForbiddenPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Rts>
  );
}
