import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export default function ProgramCountTable() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Calculate program distribution
  const programDistribution = entries.reduce((acc, entry) => {
    if (entry.meta.Registrants) {
      Object.values(entry.meta.Registrants)
        .filter((registrant) => registrant && registrant["bpc1r3"])
        .forEach((registrant) => {
          const programs = Array.isArray(registrant["bpc1r3"])
            ? registrant["bpc1r3"]
            : [registrant["bpc1r3"]];

          programs.forEach((program) => {
            acc[program] = (acc[program] || 0) + 1;
          });
        });
    }
    return acc;
  }, {} as { [program: string]: number });

  const programsData = Object.entries(programDistribution).map(
    ([program, count]) => ({
      name: program,
      value: count,
    })
  );

  return (
    <div className="overflow-y-scroll rounded-lg bg-white shadow no-scrollbar">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Program
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Count
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {programsData.map((programData, idx) => (
            <tr key={idx}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {programData.name}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {programData.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
